<template>
  <div>
    <van-nav-bar
      title="领取记录"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />

    <div class="orders">
      <ul class="orders-cont" v-for="(item, index) in dataList" :key="index">
        <li class="orders-item">
          <div class="orders-item-name">
            <img
              v-if="item.vipType == '爱奇艺视频会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/aiqiyi.png"
              alt=""
            />
            <img
              v-if="item.vipType == '网易云音乐会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/wangyiyun.png"
              alt=""
            />
            <img
              v-if="item.vipType == '芒果TV会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/mangguo.png"
              alt=""
            />
            <img
              v-if="item.vipType == 'QQ音乐豪华会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/qq_music.png"
              alt=""
            />
            <img
              v-if="item.vipType == '喜马拉雅巅峰会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/ximalaya.png"
              alt=""
            />
            <img
              v-if="item.vipType == '酷狗音乐豪华会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/kugou.png"
              alt=""
            />
            <img
              v-if="item.vipType == '腾讯视频VIP会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/tengxun.png"
              alt=""
            />
            <img
              v-if="item.vipType == '优酷黄金VIP会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/youku.png"
              alt=""
            />
            <img
              v-if="item.vipType == 'QQ会员月卡'"
              class="orders-item-name-icon"
              src="../assets/image/qq_vip.png"
              alt=""
            />
            <span class="orders-item-name-text">{{ item.vipType }}</span>
          </div>
          <div class="orders-item-msgs">
            <div class="orders-item-msgs-item">
              <span class="orders-item-msgs-label">充值帐号:</span
              ><span class="orders-item-msgs-value">{{ item.account }}</span>
            </div>
            <div class="orders-item-msgs-item">
              <span class="orders-item-msgs-label">兑换日期:</span
              ><span class="orders-item-msgs-value"> {{ item.gmtCreate }}</span>
            </div>
            <div class="orders-item-msgs-item">
              <span class="orders-item-msgs-label">当前状态:</span>
              <van-tag plain type="success" >{{item.statusDescribe}}</van-tag>
            </div>
            <div class="orders-item-status open">{{ index + 1 }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  name: "Record",
  computed: {
    ...mapState(["token", "eligibilityId"]),
  },
  data() {
    return {
      bg: {
        backgroundImage: "",
      },
      id: "",
      dataList: null,
      orderForm: {
        token: "",
        eligibilityId: "",
      },
      show: false,
      errorMessage: "",
    };
  },
  created() {
    this.getParams();
    // let param = new URLSearchParams();
    // param.append("phone", this.id);
    this.orderForm.token = this.token;
    this.orderForm.eligibilityId = this.eligibilityId;
    this.axios
      .post("/orders/list", this.orderForm, {
        headers: {
          // Accept: "*/*",
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.data.code != 30000) {
          //弹出提示
          // this.errorMessage = res.data.data.message;
          // this.show = true;
          this.$notify(res.data.data.message);
        } else {
          this.$toast(res.data.data.message);
          this.dataList = res.data.data.orderList;
          this.dataList.forEach((element) => {
            element.gmtCreate = moment(element.gmtCreate).format(
              "YYYY-MM-DD h:mm:ss"
            );
          });
        }
      });
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    getParams() {
      // 取到路由带过来的参数
      var routerParams = this.$route.params.id;
      // 将数据放在当前组件的数据内
      this.id = routerParams;
    },

  },
  watch: {
    // 监测路由变化,只要变化了就调用获取路由参数方法将数据存储本组件即可
    $route: "getParams",
  },
};
</script>

<style lang="scss" scoped>
.orders {
  min-height: 100vh;
  background-color: #f3f3f3;

  .orders-cont {
    padding: 0.5rem;

    .orders-item {
      position: relative;
      padding: 0.75rem;
      margin-bottom: 0.55rem;
      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: 0 0.125rem 0.25rem 0 hsl(0deg 0% 68% / 41%);
    }

    .orders-item-name {
      font-size: 0;
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 0.3rem;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .orders-item-name-icon {
        width: 1.25rem;
        height: 1.25rem;
        vertical-align: middle;
        margin-right: 0.3rem;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
      }

      .orders-item-name-text {
        color: #222;
        font-size: 0.75rem;
      }
    }

    .orders-item-msgs {
      .orders-item-msgs-item {
        font-size: 0;
        padding: 0.6rem 0 0.3rem 0;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        .orders-item-msgs-label {
          color: #b1b1b1;
          font-size: 0.65rem;
          margin-right: 0.6rem;
        }

        .orders-item-msgs-value {
          color: #666;
          font-size: 0.75rem;
        }
      }
    }

    .orders-item-status {
      position: absolute;
      color: #fff;
      right: 0;
      top: 0;
      font-size: 0.75rem;
      padding: 0.45rem 0.75rem;
      background-color: #9fa4b0;
      border-top-right-radius: 0.45rem;
      border-bottom-left-radius: 0.45rem;
      &.open {
        background-color: #2bc78e;
      }
    }
  }
}
.pop-title {
  margin: 3rem 0 2.2rem;
  color: #222;
  font-size: 16px;
  text-align: center;
}
.pop-btn-group {
  width: 3rem;
  margin: 0 auto;
  .van-button--plain {
    margin: 0 auto;
  }
}
.van-tag {
  line-height: 1.3rem;
}
</style>